import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import EnuitDialog from '../../components/dialog/EnuitDialog';
import IUser from '../../interfaces/users/IUser';
import useAxios from '../../hooks/useAxios';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';

type AddNewUserRoleProps = {
  open: boolean;
  onClose: () => void;
  users: IUser[];
  roleName: string;
  onAdd: (users: IUser[]) => void;
}

function AddNewUserRole(props: AddNewUserRoleProps) {
  const { open, onClose, users, roleName, onAdd } = props;
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setSelectedUsers([]);
  }, [open])

  const handleSelectionChange = (value: IUser[]) => {
    setSelectedUsers(value);
  }

  const handleAddUsers = async () => {
    try {
      await axios.post('admin/Role/Users?roleId='+roleName, selectedUsers);
      onAdd(selectedUsers);
      onClose();
      setAlertMessage('The selected users were added successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while adding the users to this role.');
    }
  }

  const getDisplayString = (user: IUser) => {
    let ret = '';
    if (user.name !== null) {
      ret += `${user.name} - `
    }

    if (user.userName !== null) {
      ret += `${user.userName} - `
    }

    if (user.email !== null) {
      ret += `${user.email}`
    }

    if (ret.endsWith(' - ')) {
      ret = ret.substring(0, ret.length - 3);
    }

    return ret;
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='Add User Role'
      onOkClick={handleAddUsers}
    >
      <Typography>Users</Typography>
      <Autocomplete
        autoHighlight
        multiple
        size='small'
        disableCloseOnSelect
        disableClearable
        limitTags={2}
        value={selectedUsers}
        onChange={(e,value,r,d) => handleSelectionChange(value)}
        options={users}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={user => getDisplayString(user)}
        // getOptionLabel={user => `${user.name ?? 'N/A'} - ${user.userName} - ${user.email}`}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </EnuitDialog>
  )
}

export default AddNewUserRole