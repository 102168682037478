import React, { useState, useEffect, useContext } from 'react';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import IUser from '../../../interfaces/users/IUser';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useAxios from '../../../hooks/useAxios';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

type UserClientDialogProps = {
  open: boolean;
  onClose: () => void;
  clientId: string;
  possibleUsers: IUser[];
  existingUsers: IUser[];
  onSave: (selectedUsers: IUser[]) => void;
}

function UserClientsDialog(props: UserClientDialogProps) {
  const { open, onClose, clientId, possibleUsers, existingUsers, onSave } = props;
  const [selectedUsers, setSelectedUsers] = useState(existingUsers);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() =>{
    setSelectedUsers(existingUsers);
  }, [open]);

  const handleSave = async () => {
    try {
      const originalIds = existingUsers.map(user => user.id);
      const currentIds = selectedUsers.map(user => user.id);

      const newlyAddedUsers = selectedUsers.filter(user => !originalIds.includes(user.id));
      for (const user of newlyAddedUsers) {
        await axios.post('admin/Client/Users', {userID: user.id, clientId: clientId});
      }

      const newlyDeletedUsers = existingUsers.filter(user => !currentIds.includes(user.id));
      for (const user of newlyDeletedUsers) {
        await axios.delete('admin/Client/Users', {data: {userID: user.id, clientId: clientId}});
      }

      onSave(selectedUsers);
      onClose();
      setAlertMessage('Saved successfully.');
    } catch (error) {
      console.log('An error occurred while updating the users clients link.', error);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title={'Link users to ' + clientId}
      onOkClick={handleSave}
    >
      <Autocomplete
        autoHighlight
        multiple
        filterSelectedOptions
        disableCloseOnSelect
        renderInput={params => <TextField {...params} />}
        options={possibleUsers}
        getOptionLabel={user => `${user.name} - ${user.userName}`}
        value={selectedUsers}
        onChange={(e, value) => setSelectedUsers(value)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
    </EnuitDialog>
  )
}

export default UserClientsDialog