import React, { useState, useRef, useEffect } from 'react'
import Form, {
  Item as FormItem,
  RequiredRule,
  EmailRule,
  CompareRule,
  PatternRule,
  SimpleItem
} from 'devextreme-react/form';
import { FieldDataChangedEvent } from 'devextreme/ui/form';
import IUser, { INewUser } from '../../../interfaces/users/IUser';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import useAxios from '../../../hooks/useAxios';
import { Autocomplete, Box, Button, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import ax from 'axios';
import '../users.scss';
import IIdentityProvider from '../../../interfaces/identity/IIdentityProvider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


interface INewUserDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (user: IUser) => void;
}

function NewUserDialog(props: INewUserDialogProps) {
  const { open, onClose, onAdd } = props;
  const emptyUser: INewUser = {
    id: '', userName: '', name: '', email: null, phoneNumber: null, emailConfirmed: false, isLockedOut: false, password: '', confirmPassword: '', isInternal: true,
    objectIdentifierId: null, providerDisplayName: null
  }
  const [newUser, setNewUser] = useState(emptyUser);
  const [saveErrorMessage, setSaveErrorMessage] = useState('');
  const [viewPassword, setViewPassword] = useState(true);
  const [providers, setProviders] = useState<IIdentityProvider[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<IIdentityProvider | null>(null);
  const [newEmail, setNewEmail] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const [showSearchButton, setShowSearchButton] = useState(true);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const axios = useAxios();

  const dxFormRef = useRef<Form | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    loadProviders();
  }, []);

  const loadProviders = async () => {
    const response = await axios.get<IIdentityProvider[]>('Identity/IdentityProvider');
    setProviders(response.data);
  }

  useEffect(() => {
    setSaveErrorMessage('');
    setSelectedProvider(null);
    setNewEmail('');
    setShowSearchButton(true);
    setNewUser(emptyUser);
    dxFormRef.current?.instance.resetValues();
  }, [open, selectedTab]);

  useEffect(() => {
    setShowSearchButton(true);
    setSearchErrorMessage('');
  }, [newEmail, selectedProvider]);

  //#region Dx Form
  function handleFormChange(e: FieldDataChangedEvent) {
    if (e.dataField === undefined) {
      return;
    }

    const updatedValue: INewUser = {
      ...newUser,
      [e.dataField]: e.value
    }
    setNewUser(updatedValue);
  }

  //only called after validation succeeds
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationResults = dxFormRef.current?.instance.validate();
    if (!validationResults?.isValid) {
      return;
    }
    try {
      setSaveErrorMessage('');
      const response = await axios.post<IUser>('Identity/Register', { ...newUser });
      onAdd(response.data);
      onClose();
      return false;
    } catch (error) {
      if (ax.isAxiosError(error)) {
        setSaveErrorMessage((error.response?.data as { message: string }).message as string);
      }
      console.log('An error occurred while saving the new user', error);
    }
  }

  //#endregion

  const handleSearchExternalUser = async () => {
    try {
      const details = {
        email: newEmail,
        authenticationScheme: selectedProvider
      }
      const response = await axios.post<INewUser>('Identity/Register/external-find', details);
      setNewUser(response.data);
      setShowSearchButton(false);

      if (!response.data.userName) {
        setSearchErrorMessage('This user could not be found.');
      }
    } catch (error) {
      if (ax.isAxiosError(error)) {
        const emailError = (error.response?.data as any)?.errors?.Email;
        if (emailError) {
          setSearchErrorMessage('Please search with a valid email.');
        }
      } else {
        console.log('An error occurred while searching for an external user', error);
      }
    }
  }

  const handleOkClick = () => {
    if (selectedTab === 0) {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    } else if (selectedTab === 1) {
      handleAddExternalUser();
    }
  }

  const handleAddExternalUser = async () => {
    if (!newUser.userName) {
      setSearchErrorMessage('Please select a valid external user.');
      return;
    }

    try {
      const userToAdd = { ...newUser, authenticationScheme: selectedProvider!, email: newEmail };
      setSaveErrorMessage('');

      const response = await axios.post<IUser>('Identity/Register/external-save', { ...userToAdd });
      onAdd(response.data);
      onClose();
    } catch (error) {
      if (ax.isAxiosError(error)) {
        setSaveErrorMessage((error.response?.data as { message: string }).message as string);
      } else {
        console.log('An error occurred while saving the new user', error);
      }
    }
  }

  return (
    <EnuitDialog
      title='New User'
      open={open}
      onClose={onClose}
      onOkClick={handleOkClick}
    >
      <Tabs value={selectedTab} onChange={(e, value: number) => setSelectedTab(value)}>
        <Tab label='Internal User' />
        <Tab label='External User' />
      </Tabs>
      <TabPanel index={0} value={selectedTab}>
        <form onSubmit={handleFormSubmit} ref={formRef}>
          <Form
            id='new-user-form'
            labelMode='outside'
            labelLocation='top'
            formData={newUser}
            onFieldDataChanged={handleFormChange}
            validationGroup='userData'
            ref={dxFormRef}
            showColonAfterLabel={false}
            showRequiredMark={false}
          >
            <FormItem dataField='userName' isRequired />
            <FormItem dataField='name' isRequired />
            <FormItem dataField='email'>
              <RequiredRule message='Email is required' />
              <EmailRule message='Please enter a valid email' />
            </FormItem>
            <FormItem dataField='phoneNumber' />
            <SimpleItem
              dataField='password'
              editorOptions={
                {
                  mode: viewPassword ? 'password' : '',
                  buttons: [{
                    name: 'trash',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'material-icons ic-face',
                      onClick: () => {
                        setViewPassword(prev => !prev)
                      },
                    },
                  }]
                }
              }
            >
              <RequiredRule message='Password is required' />
              <PatternRule
                message='Password must contain at least one uppercase letter, one number, and one special character'
                pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$'
              />
            </SimpleItem>
            <SimpleItem
              dataField='confirmPassword'
              isRequired
              editorOptions={
                {
                  mode: viewPassword ? 'password' : '',
                  buttons: [{
                    name: 'trash',
                    location: 'after',
                    options: {
                      stylingMode: 'text',
                      icon: 'material-icons ic-face',
                      onClick: () => {
                        setViewPassword(prev => !prev)
                      },
                    },
                  }]
                }
              }
            >
              <RequiredRule message='Confirm Password is required' />
              <CompareRule message='The passwords do not match' comparisonTarget={() => { return newUser.password }} />
            </SimpleItem>
          </Form>
        </form>
        {saveErrorMessage !== '' && <Typography color='error'>Error: {saveErrorMessage}</Typography>}
        <Box m={2}>

        </Box>
      </TabPanel>
      <TabPanel index={1} value={selectedTab}>
        <Stack direction='column' spacing={1}>
          <Box>
            <Typography>Provider</Typography>
            <Autocomplete
              autoHighlight
              options={providers}
              value={selectedProvider!}
              onChange={(e, value, r, d) => setSelectedProvider(value)}
              renderInput={props => <TextField {...props} />}
              size='small'
              getOptionLabel={provider => provider.displayName}
              isOptionEqualToValue={(option, value) => option.displayName === value.displayName}
              disableClearable
            />
          </Box>
          <Box>
            <Typography>Email</Typography>
            <Stack direction='row' spacing={1}>
              <TextField
                value={newEmail}
                onChange={e => setNewEmail(e.target.value)}
                size='small'
                fullWidth
              />
              {showSearchButton &&
                <Button
                  disabled={newEmail === '' || selectedProvider === undefined}
                  variant='outlined'
                  onClick={handleSearchExternalUser}
                >
                  Search
                </Button>
              }
            </Stack>
          </Box>
          <Box>
            <Typography>Name</Typography>
            <TextField
              size='small'
              fullWidth
              value={newUser.name ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box>
            <Typography>User Name</Typography>
            <TextField
              size='small'
              fullWidth
              value={newUser.userName ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Typography color='error'>{searchErrorMessage}</Typography>
        </Stack>
      </TabPanel>
    </EnuitDialog>
  );
}



export default NewUserDialog