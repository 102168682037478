import React, { useContext, useEffect, useState } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import IRole from '../../../interfaces/roles/IRole';
import useAxios from '../../../hooks/useAxios';
import { Autocomplete, TextField } from '@mui/material';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

interface INewUserRoleDialogProps {
  open: boolean;
  onClose: () => void;
  roles: IRole[];
  userId: string;
  onAdd: (newUserRole: IRole) => void;
}

function NewUserRoleDialog(props: INewUserRoleDialogProps) {
  const { open, onClose, roles, userId, onAdd } = props;
  const [newRole, setNewRole] = useState<IRole | null>(null);
  const [error, setError] = useState(false);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  const handleNewUserRole = async () => {
    if (newRole === null) {
      setError(true);
      return;
    }

    try {
      const newUserRole = roles.find(role => role.name === newRole.name)!;
      await axios.post(`admin/User/Role?userId=${userId}&roleName=${newUserRole.name}`);

      setNewRole(null);
      onAdd(newUserRole);
      onClose();
      setAlertMessage('Role successfully added to user.');
    } catch (error) {
      console.log('An error occurred while adding a new user role', error);
      setAlertMessage('An error occurred while adding a new user role.');
    }
  }

  useEffect(() => {
    setNewRole(null);
    setError(false);
  }, [open])

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New User Role'
      onOkClick={handleNewUserRole}
    >
      <FormControl fullWidth error={error} size='small'>
        <Typography variant='body2'>Role</Typography>
        <Autocomplete
          autoHighlight
          size='small'
          options={roles}
          value={newRole}
          onChange={(e, value, r, d) => setNewRole(value)}
          renderInput={params => <TextField {...params} error={error} />}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
        />
        {error && <FormHelperText>Required</FormHelperText>}
      </FormControl>
    </EnuitDialog>
  )
}

export default NewUserRoleDialog