import React, { useState, useEffect, useContext } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import { FormControl, FormHelperText, TextField, Typography, Autocomplete } from '@mui/material';
import IClaim from '../../../interfaces/claims/IClaim';
import IUserClaim from '../../../interfaces/users/IUserClaim';
import useAxios from '../../../hooks/useAxios';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

interface INewUserClaimsProps {
  open: boolean;
  onClose: () => void;
  userId: string;
  claims: IClaim[];
  onAdd: (newUserClaim: IUserClaim) => void;
}

function NewUserClaimDialog(props: INewUserClaimsProps) {
  const { open, onClose, userId, claims, onAdd } = props;
  const [error, setError] = useState(false);
  const [newClaimType, setNewClaimType] = useState<IClaim | null>(null);
  const [newClaimValue, setNewClaimValue] = useState('');
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setNewClaimType(null);
    setNewClaimValue('');
    setError(false);
  }, [open]);

  const handleOkClick = async () => {
    if (newClaimType === null || newClaimValue === '') {
      setError(true);
      return;
    }

    try {
      const newUserClaim = { type: newClaimType.type, value: newClaimValue };
      await axios.post<IUserClaim>('admin/User/Claim?userId=' + userId, { ...newUserClaim });

      setNewClaimType(null);
      setNewClaimValue('');
      onAdd(newUserClaim);
      setAlertMessage('Claim successfully added to user.');
    } catch (error) {
      setAlertMessage('An error occurred while adding a new user claim.');
      console.log('An error occurred while adding a new user claim.', error);
    }

    onClose();
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='New User Claim'
      onOkClick={handleOkClick}
    >
      <FormControl fullWidth size='small' error={error && newClaimType === null}>
        <Typography variant='body2'>Claim Type</Typography>
        <Autocomplete
          autoHighlight
          options={claims}
          value={newClaimType}
          onChange={(e, value, r, d) => setNewClaimType(value)}
          renderInput={params => <TextField {...params} error={error && newClaimType === null} />}
          getOptionLabel={option => `${option.description ?? ''} ${option.description && '-'} ${option.type}`}
          isOptionEqualToValue={(option, value) => option.type === value.type}
          size='small'
        />
        {error && newClaimType === null && <FormHelperText>Claim Type is required</FormHelperText>}
        <Typography variant='body2' sx={{ marginTop: '16px' }}>Value</Typography>
        <TextField
          error={error && newClaimValue === ''}
          helperText={error && newClaimValue === '' ? 'Value is required' : ''}
          size='small'
          value={newClaimValue}
          onChange={(e) => setNewClaimValue(e.target.value)}
        />
      </FormControl>
    </EnuitDialog>
  );
}

export default NewUserClaimDialog