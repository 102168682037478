import React, { useState, useEffect, useContext } from 'react'
import EnuitDialog from '../../../components/dialog/EnuitDialog'
import IClient from '../../../interfaces/clients/IClient';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { sortByClientId } from '../../clients/Clients';
import useAxios from '../../../hooks/useAxios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

interface INewUserClientDialogProps {
  open: boolean;
  onClose: () => void;
  clients: IClient[];
  userId: string;
  onAdd: (clients: IClient[]) => void
}

function NewUserClientDialog(props: INewUserClientDialogProps) {
  const { open, onClose, clients, userId, onAdd } = props;
  const [selectedClients, setSelectedClients] = useState<IClient[]>([]);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setSelectedClients([]);
  }, [open])

  const handleSelectionChange = (value: IClient[]) => {
    setSelectedClients(value);
  }

  const handleAddClients = async () => {
    try {
      for (const client of selectedClients) {
        await axios.post<IClient>('admin/User/Client?userId=' + userId, { ...client });
      }
      onAdd(selectedClients);
      onClose();
      setAlertMessage('Clients successfully added to this user.');
    } catch (error) {

    }
  }

  const getDisplayString = (client: IClient) => {
    let ret = '';
    if (client.clientId !== null) {
      ret += `${client.clientId} - `
    }

    if (client.clientName !== null) {
      ret += `${client.clientName} - `
    }

    if (ret.endsWith(' - ')) {
      ret = ret.substring(0, ret.length - 3);
    }

    return ret;
  }


  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title='Link client to user'
      onOkClick={handleAddClients}
    >
      <Typography>Clients</Typography>
      <Autocomplete
        autoHighlight
        multiple
        size='small'
        disableCloseOnSelect
        disableClearable
        limitTags={2}
        value={selectedClients}
        onChange={(e, value, r, d) => handleSelectionChange(value)}
        options={clients}
        renderInput={params => <TextField {...params} />}
        getOptionLabel={user => getDisplayString(user)}
        isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
      />

    </EnuitDialog>
  )
}

export default NewUserClientDialog