import React, { useState } from 'react'
import EnuitDialog from '../../components/dialog/EnuitDialog'
import IClaim from '../../interfaces/claims/IClaim';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useAxios from '../../hooks/useAxios';

interface NewClaimTypeDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (newClaim: IClaim) => void;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
}

function NewClaimTypeDialog(props: NewClaimTypeDialogProps) {
  const { open, onClose, onAdd, setAlertMessage } = props
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const axios = useAxios();

  const handleAdd = async () => {
    try {
      const newClaim = {
        e7ClaimTypeId: 0,
        type: type,
        description: description,
        valueType: description
      }

      const response = await axios.post<IClaim>('admin/ClaimType', { ...newClaim });
      onAdd(response.data);
      onClose();
      setAlertMessage('The new claim type was created successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while creating the new claim type.')
      console.log('An error occurred while adding the new claim type', error);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      onOkClick={handleAdd}
      title='New Claim Type'
    >
      <Box display='flex' flexDirection='column' rowGap='16px'>
        <Box>
          <Typography>Type</Typography>
          <TextField
            fullWidth
            size='small'
            value={type}
            onChange={e => setType(e.target.value)}
          />
        </Box>
        <Box>
          <Typography>Description</Typography>
          <TextField
            fullWidth
            size='small'
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Box>
      </Box>
    </EnuitDialog>
  )
}

export default NewClaimTypeDialog