import React, { PropsWithChildren, createContext, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const defaultContext: [string, React.Dispatch<React.SetStateAction<string>>] = ['', () => console.log('The set state is not initialized')];
export const EnuitAlertContext = createContext<[string, React.Dispatch<React.SetStateAction<string>>]>(defaultContext);

function EnuitAlert(props: PropsWithChildren<{}>) {
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'error'>('error');

  useMemo(() => {
    if (alertMessage === '') {
      return;
    }

    const newSeverity = alertMessage.toLocaleLowerCase().includes('success') ? 'success' : 'error';
    setSeverity(newSeverity);
  }, [alertMessage]);

  return (
    <>
      <Snackbar open={alertMessage !== ''} autoHideDuration={3000} onClose={() => setAlertMessage('')}>
        <Alert variant='filled' onClose={() => setAlertMessage('')} severity={severity}>{alertMessage}</Alert>
      </Snackbar>
      <EnuitAlertContext.Provider value={[alertMessage, setAlertMessage]}>
        {props.children}
      </EnuitAlertContext.Provider>
    </>
  )
}

export default EnuitAlert