import React, { useEffect, useState, useRef, useContext } from 'react'
// MUI
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
// Other
import { useNavigate, useParams } from 'react-router-dom'
import ClientDetails from './components/ClientDetails';
import IClient, { IClientProvider } from '../../interfaces/clients/IClient';
import useAxios from '../../hooks/useAxios';
import IApiResource from '../../interfaces/apiResources/IApiResource';
import { IGrantType } from '../../interfaces/clients/IClientGrantType';
import ILogoutUri from '../../interfaces/clients/IClientLogoutUri';
import IRedirectUri from '../../interfaces/clients/IClientRedirectUri';
import ClientManagementLoadHandler from '../../services/clients/loadClients';
import ClientManagementSaveHandler from '../../services/clients/saveClients';
import NewClientRedirectDialog from './dialogs/NewClientRedirectDialog';
import NewClientLogoutRedirectDialog from './dialogs/NewClientLogoutRedirectDialog';
import NewClientAllowedCorsDialog from './dialogs/NewClientAllowedCorsDialog';
import ConfirmDeleteDialog from '../../components/dialog/ConfirmDeleteDialog';
import GenerateClientTokenDialog from './dialogs/GenerateClientTokenDialog';
import { generateQueryParams, IUserSearchParams, userIdQueryParamKey } from '../../hooks/useQueryParams';
import { Alert, Snackbar } from '@mui/material';
import NewClientProviderDialog from './dialogs/NewClientProviderDialog';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';

export const emptyClient: IClient = {
  clientId: '', clientName: '', description: '', clientUri: '', logoUri: '', requireClientSecret: false,
  requireConsent: false, requirePkce: false, allowOfflineAccess: false, absoluteRefreshTokenLifetime: 24,
  enabled: true
}

const defaultGrantType: IGrantType = {
  'authorization_code': false,
  'client_credentials': false,
  'password': false
}

function ClientManagement() {
  const { clientId } = useParams();

  const [clientIdErrorMessage, setClientIdErrorMessage] = useState('');
  const [client, setClient] = useState<IClient>(emptyClient);
  const [clientScopes, setClientScopes] = useState<IApiResource[]>([]);
  const [clientGrantTypes, setClientGrantTypes] = useState<IGrantType>(defaultGrantType);
  const [clientRedirectURIs, setClientRedirectURIs] = useState<IRedirectUri[]>([]);
  const [clientLogoutURIs, setClientLogoutURIs] = useState<ILogoutUri[]>([]);
  const [allowedCors, setAllowedCors] = useState<ILogoutUri[]>([]);
  const [possibleScopes, setPossibleScopes] = useState<IApiResource[]>([]);
  const [hasSecret, setHasSecret] = useState(false);
  const [newSecret, setNewSecret] = useState('');
  const [providers, setProviders] = useState<IClientProvider[]>([]);
  const [providerErrorOpen, setProviderErrorOpen] = useState(false);
  const originalClient = useRef<IClient>(emptyClient);
  const originalClientScopes = useRef<IApiResource[]>([]);
  const originalClientGrantTypes = useRef<IGrantType>(defaultGrantType);
  const originalRedirectURIs = useRef<IRedirectUri[]>([]);
  const originalLogoutURIs = useRef<ILogoutUri[]>([]);
  const originalCors = useRef<ILogoutUri[]>([]);
  const originalProviders = useRef<IClientProvider[]>([]);
  const [newRedirectOpen, setNewRedirectOpen] = useState(false);
  const [newLogoutOpen, setNewLogoutOpen] = useState(false);
  const [newCorsOpen, setNewCorsOpen] = useState(false);
  const [generateTokenOpen, setGenerateTokenOpen] = useState(false);
  const [newProviderOpen, setNewProviderOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savingSecret, setSavingSecret] = useState(false);
  const [deletingSecret, setDeletingSecret] = useState(false);
  const axios = useAxios();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  let loadHandler = new ClientManagementLoadHandler(axios, clientId!);
  let saveHandler = new ClientManagementSaveHandler(axios, clientId!);

  useEffect(() => {
    loadHandler = new ClientManagementLoadHandler(axios, clientId!);
    saveHandler = new ClientManagementSaveHandler(axios, clientId!);
    loadData();
  }, [clientId])

  //#region LoadData

  const loadData = async () => {
    loadClient();
    loadScopes();
    loadGrantTypes();
    loadRedirects();
    loadLogoutUri();
    loadCors();
    loadSecret();
    loadProviders();
  }

  const loadClient = async () => {
    const client = await loadHandler.loadClient();
    if (client !== undefined) {
      setClient(client);
      originalClient.current = client;
    }
  }

  const loadScopes = async () => {
    const scope = await loadHandler.loadPossibleScopes();
    if (scope !== undefined) {
      setPossibleScopes(scope.possibleScopes);
      setClientScopes(scope.clientScopes);
      originalClientScopes.current = scope.clientScopes;
    }
  }

  const loadGrantTypes = async () => {
    const grantTypes = await loadHandler.loadClientGrantTypes();
    if (grantTypes !== undefined) {
      setClientGrantTypes(grantTypes);
      originalClientGrantTypes.current = grantTypes;
    }
  }

  const loadRedirects = async () => {
    const redirects = await loadHandler.loadClientRedirects();
    if (redirects !== undefined) {
      setClientRedirectURIs(redirects);
      originalRedirectURIs.current = redirects
    }
  }

  const loadLogoutUri = async () => {
    const logouts = await loadHandler.loadPostLogoutRedirects();
    if (logouts !== undefined) {
      setClientLogoutURIs(logouts);
      originalLogoutURIs.current = logouts;
    }
  }

  const loadCors = async () => {
    const cors = await loadHandler.loadAllowedCors();
    if (cors !== undefined) {
      setAllowedCors(cors);
      originalCors.current = cors;
    }
  }

  const loadSecret = async () => {
    const secret = await loadHandler.loadSecret();
    if (secret !== undefined) {
      setHasSecret(secret);
    }
  }

  const loadProviders = async () => {
    const providers = await loadHandler.loadClientProviders();
    if (providers !== undefined) {
      setProviders(providers);
      originalProviders.current = providers;
    }
  }

  //#endregion

  const handleSave = async () => {
    if (client.clientId === '') {
      setClientIdErrorMessage('Id is required');
      return;
    }

    if (clientIdErrorMessage !== '') {
      setClientIdErrorMessage('');
    }

    const currentProviders = providers.filter(provider => provider.isSelected);
    if (currentProviders.length === 0) {
      setProviderErrorOpen(true);
      return;
    }

    try {
      setSaving(true);
      const clientResponse = saveHandler.saveClient(client);
      const scopeResponse = saveHandler.saveScopes(clientScopes, originalClientScopes.current);
      const grantResponse = saveHandler.saveGrants(clientGrantTypes, originalClientGrantTypes.current);
      const redirectResponse = saveHandler.saveRedirects(clientRedirectURIs, originalRedirectURIs.current);
      const logoutResponse = saveHandler.saveLogouts(clientLogoutURIs, originalLogoutURIs.current);
      const corsResponse = saveHandler.saveCORS(allowedCors, originalCors.current);
      const providersResponse = saveHandler.saveProviders(providers);

      const results = await Promise.all([clientResponse, scopeResponse, grantResponse, redirectResponse, logoutResponse, corsResponse, providersResponse]);
      const savedSuccessfully = results.every(result => result);
      if (savedSuccessfully) {
        setAlertMessage('The client was updated successfully.');
      } else {
        //TODO have the error message show which part of the client didn't save
        setAlertMessage('There was an error when saving the client.');
      }
      setSaving(false);
    } finally {
      setSaving(false);
    }
  }

  const handleSetSecret = async () => {
    try {
      setSavingSecret(true);
      await axios.post('admin/Client/Secrets', { clientId: clientId, name: newSecret });
      setNewSecret('');
      setHasSecret(true);
      setAlertMessage('The secret saved successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while adding a new secret.');
      console.log('An error occurred while adding a new secret', error);
    }
    finally {
      setSavingSecret(false);
    }
  }

  const handleDeleteSecret = async () => {
    try {
      setDeletingSecret(true);
      await axios.delete('admin/Client/Secrets?clientid=' + clientId);
      setNewSecret('');
      setHasSecret(false);
      setAlertMessage('The secret was reset successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while resetting the secret.');
      console.log('An error occurred while resetting the secret', error);
    }
    finally {
      setDeletingSecret(false);
    }
  }

  const getScopeNames = React.useMemo(() => {
    return clientScopes.map(a => a.name);
  }, [clientScopes]);

  const handleViewUsers = () => {
    const queryParams = generateQueryParams<IUserSearchParams>(userIdQueryParamKey, {selectedUserId: '', filterClientId: clientId!});

    //Not sure where the extra users page param is coming from
    navigate('/users?' + queryParams.toString());
  }

  useEffect(() => {
    if (clientGrantTypes.client_credentials) {
      setClient(prev => ({...prev, requireClientSecret: true}));
    }
  }, [clientGrantTypes]);

  useEffect(() => {
    if (!client.requireClientSecret) {
      setClientGrantTypes(prev => ({...prev, client_credentials: false}));
    }
  }, [client]);

  const handleProviderUpdate = (provider: IClientProvider, selected: boolean) => {
    setProviders(prev => (
      [...prev].map(previousProvider => {
        if (previousProvider.displayName === provider.displayName) {
          return {
            ...previousProvider,
            isSelected: selected
          }
        }
        return previousProvider;
      })
    ));
  }

  return (
    <>
      <NewClientRedirectDialog
        open={newRedirectOpen}
        onClose={() => setNewRedirectOpen(false)}
        onAdd={e => setClientRedirectURIs(prev => ([...prev].concat(e)))}
        clientId={clientId!}
      />
      <NewClientLogoutRedirectDialog
        open={newLogoutOpen}
        onClose={() => setNewLogoutOpen(false)}
        onAdd={e => setClientLogoutURIs(prev => ([...prev].concat(e)))}
        clientId={clientId!}
      />
      <NewClientAllowedCorsDialog
        open={newCorsOpen}
        onClose={() => setNewCorsOpen(false)}
        onAdd={e => setAllowedCors(prev => ([...prev].concat(e)))}
        clientId={clientId!}
      />
      <GenerateClientTokenDialog
        open={generateTokenOpen}
        onClose={() => setGenerateTokenOpen(false)}
        scopes={getScopeNames}
        clientId={clientId!}
      />
      <NewClientProviderDialog
        open={newProviderOpen}
        onClose={() => setNewProviderOpen(false)}
        providers={providers.filter(provider => !provider.isSelected)}
        clientId={clientId!}
        onAdd={handleProviderUpdate}
      />
      <Snackbar 
        open={providerErrorOpen}
        autoHideDuration={6000}
        onClose={() => setProviderErrorOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
      >
        <Alert onClose={() => setProviderErrorOpen(false)} severity='error'>
          A client is required to have at least one provider. Please select one and try saving again.
        </Alert>
      </Snackbar>
      <Box id='client-management-page'>
        <Box display='flex' justifyContent='space-between' alignItems='center' pb={2}>
          <Stack direction='column'>
            <Button onClick={() => navigate('/clients')} startIcon={<ArrowBackIcon />} id='test' size='small'>Back to clients</Button>
            <Typography variant='subtitle1'>Client Management</Typography>
          </Stack>
          <Stack direction='row' spacing={1}>
            <LoadingButton loading={saving} loadingPosition="start" onClick={handleSave} startIcon={<SaveIcon />} variant='contained'>Save</LoadingButton>
          </Stack>
        </Box>
        <Grid container spacing={2}>
          <Grid md={6} xs={12}>
            <Stack direction='column' spacing={2}>
              <Paper elevation={3} sx={{ p: '16px' }}>
                <Stack direction='column' spacing={1}>
                  <Typography variant='subtitle1'>Client Details</Typography>
                  <ClientDetails areDetailsUpdating idErrorMessage={clientIdErrorMessage} client={client} handleUpdate={(updates) => setClient(prev => { return { ...prev, ...updates } })} />
                  <span>
                    <Button onClick={handleViewUsers} variant='outlined'>View Users</Button>
                  </span>
                </Stack>
              </Paper>
              <Paper elevation={3} sx={{ p: '16px' }}>
                <Stack direction='column' spacing={2}>
                  <Box>
                    <Typography variant='subtitle1'>Client Secret</Typography>
                    {client.requireClientSecret && (hasSecret
                      ?
                      <Typography color='success.main' variant='body1'>{<CheckCircleIcon color='inherit' fontSize='inherit' />} 1 or more secrets exist</Typography>
                      :
                      <Typography color='info.main' variant='body1'>{<CancelIcon color='inherit' fontSize='inherit' />} No Secrets Exist</Typography>
                    )}
                  </Box>
                  <Stack direction='row' spacing={2}>
                    <TextField
                      id='generate-client-secret'
                      fullWidth
                      size='small'
                      value={newSecret}
                      onChange={e => setNewSecret(e.target.value)}
                      disabled={!client.requireClientSecret}
                      helperText={!client.requireClientSecret ? 'Require Secret must be checked before setting the secret' : ''}
                      type='password'
                    />
                    <span>
                      <LoadingButton
                        loading={savingSecret}
                        loadingPosition="start"
                        onClick={handleSetSecret}
                        startIcon={<SaveIcon />}
                        variant='outlined'
                        disabled={newSecret === ''}
                      >
                        Save
                      </LoadingButton>
                    </span>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <span>
                      <LoadingButton
                        loading={deletingSecret}
                        loadingPosition="start"
                        onClick={handleDeleteSecret}
                        startIcon={<DeleteForeverIcon />}
                        variant='outlined'
                        disabled={!hasSecret}
                        color='error'
                      >
                        Reset Secret
                      </LoadingButton>
                    </span>
                    <span>
                      <FormControl>
                        <span>
                          <Button disabled={clientScopes.length === 0} variant='outlined' onClick={() => setGenerateTokenOpen(true)}>Generate Token</Button>
                        </span>
                        <FormHelperText>{clientScopes.length === 0 ? 'A scope is required to generate a token' : ''}</FormHelperText>
                      </FormControl>
                    </span>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid md={6} xs={12}>
            <Paper elevation={3} sx={{ p: '16px' }}>
              <Typography variant='subtitle1'>Client Attributes</Typography>
              <Typography variant='subtitle2' sx={{ marginTop: '16px' }}>Allowed Scopes</Typography>
              <Autocomplete
                autoHighlight
                multiple
                disableCloseOnSelect
                disableClearable
                filterSelectedOptions
                id="tags-standard"
                size='small'
                options={possibleScopes}
                value={clientScopes}
                onChange={(e, value, r, d) => setClientScopes(value)}
                getOptionLabel={(option) => `${option.resourceType}: ${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Scopes..."
                  />
                )}
                isOptionEqualToValue={(option, value) => option.resourceType === value.resourceType && option.name === value.name}
              />
              <Divider sx={{ mt: '16px', mb: '16px' }} />
              <Typography variant='subtitle2'>Allowed Grant Types</Typography>
              <FormGroup>
                <FormControlLabel
                  label='Client Credentials'
                  style={{ pointerEvents: 'none' }}
                  control={
                    <Checkbox
                      checked={clientGrantTypes.client_credentials}
                      onChange={e => setClientGrantTypes(prev => ({ ...prev, client_credentials: e.target.checked }))}
                      style={{ pointerEvents: 'auto' }}
                    />
                  }
                />
                <FormControlLabel
                  label='Owner Password'
                  style={{ pointerEvents: 'none' }}
                  control={
                    <Checkbox
                      checked={clientGrantTypes.password}
                      onChange={e => setClientGrantTypes(prev => ({ ...prev, password: e.target.checked }))}
                      style={{ pointerEvents: 'auto' }}
                    />
                  }
                />
                <FormControlLabel
                  label='OpenId Connect Code'
                  style={{ pointerEvents: 'none' }}
                  control={
                    <Checkbox
                      checked={clientGrantTypes.authorization_code}
                      onChange={e => setClientGrantTypes(prev => ({ ...prev, authorization_code: e.target.checked }))}
                      style={{ pointerEvents: 'auto' }}
                    />
                  }
                />
              </FormGroup>
              <Divider sx={{ mt: '16px', mb: '16px' }} />
              <Typography variant='subtitle2'>Client Providers</Typography>
              <Stack direction='column' spacing={1}>
                  {
                    providers.filter(provider => provider.isSelected).map(provider => (
                      <Stack key={provider.displayName} direction='row' spacing={1}>
                        <TextField fullWidth size='small' value={provider.displayName} />
                        <IconButton color='error' onClick={() => handleProviderUpdate(provider, false)}>
                          <RemoveCircleIcon />
                        </IconButton>
                      </Stack>
                    ))
                  }
                  <span><Button onClick={() => setNewProviderOpen(true)} startIcon={<AddIcon />} size='small'>Add Provider</Button></span>
              </Stack>
              <Divider sx={{ mt: '16px', mb: '16px' }} />
              <Typography variant='subtitle2'>Client Redirects</Typography>
              <Stack direction='column' spacing={1}>
                {clientRedirectURIs.map(uri => (
                  <Stack key={uri.redirctUri} direction='row' spacing={1}>
                    <TextField fullWidth size='small' value={uri.redirctUri} />
                    <IconButton color='error' onClick={() => setClientRedirectURIs(prev => ([...prev].filter(a => a.redirctUri !== uri.redirctUri)))}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </Stack>
                ))}
                <span><Button onClick={() => setNewRedirectOpen(true)} startIcon={<AddIcon />} size='small'>Add Redirect Uri</Button></span>
              </Stack>
              <Divider sx={{ mt: '16px', mb: '16px' }} />
              <Typography variant='subtitle2'>Post Logout Redirects</Typography>
              <Stack direction='column' spacing={1}>
                {clientLogoutURIs.map(uri => (
                  <Stack key={uri.stringValue} direction='row' spacing={1}>
                    <TextField fullWidth size='small' value={uri.stringValue} />
                    <IconButton color='error' onClick={() => setClientLogoutURIs(prev => ([...prev].filter(a => a.stringValue !== uri.stringValue)))}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </Stack>
                ))}
                <span><Button onClick={() => setNewLogoutOpen(true)} startIcon={<AddIcon />} size='small'>Add Logout Uri</Button></span>
              </Stack>
              <Divider sx={{ mt: '16px', mb: '16px' }} />
              <Typography variant='subtitle2'>Allowed CORS Origins</Typography>
              <Stack direction='column' spacing={1}>
                {allowedCors.map(cors => (
                  <Stack key={cors.stringValue} direction='row' spacing={1}>
                    <TextField fullWidth size='small' value={cors.stringValue} />
                    <IconButton color='error' onClick={() => setAllowedCors(prev => ([...prev].filter(a => a.stringValue !== cors.stringValue)))}>
                      <RemoveCircleIcon />
                    </IconButton>
                  </Stack>
                ))}
                <span><Button onClick={() => setNewCorsOpen(true)} startIcon={<AddIcon />} size='small'>Add CORS Origin</Button></span>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ClientManagement