import React from 'react'
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Searchfield from "../Searchfield/Searchfield";
import { CheckSessionIFrame } from 'oidc-client-ts';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


interface PageHeaderProps {
  pageName: string,
  searchValue: string,
  onSearchFieldChange: (searchString: string) => void,
  onChekboxChange?: (isDisabled : boolean) => void,
  button: {
    buttonText: string,
    handleButtonClick: () => void,
    startIcon?: React.ReactNode
  },
  chekbox?: { label:string , handleCheckboxClick:()=>void, enable: boolean }  
}

const PageHeader = (props: PageHeaderProps) => {
  const { pageName, searchValue, 
          onSearchFieldChange, 
          onChekboxChange= (_) =>{}, 
          button, chekbox ={ label:'', handleCheckboxClick() {}, enable: false} } = props;

  return (
    <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
      <Typography variant="subtitle1">{pageName}</Typography>
      <Stack direction='row' spacing={2}>
        <Searchfield
          value={searchValue}
          onChange={(e) => onSearchFieldChange(e.target.value)}
        />
         <div>
           {chekbox.enable &&  <FormControlLabel 
              control={
                <Checkbox onChange={(e) => onChekboxChange(e.target.checked)} />
              }
              label={chekbox.label}
              />}
           </div>
        
        <Box sx={{ flexGrow: 1 }} />
        <Button
          variant="contained"
          onClick={() => button.handleButtonClick()}
          startIcon={button.startIcon}
        >
          {button.buttonText}
        </Button>
      </Stack>
    </Stack>
  )
}



export default PageHeader;