import React, { useState, useEffect, useContext } from 'react';
import EnuitDialog from '../../components/dialog/EnuitDialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IRole from '../../interfaces/roles/IRole';
import useAxios from '../../hooks/useAxios';
import Stack from '@mui/material/Stack';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';

interface INewRoleDialogProps {
  open: boolean;
  onClose: () => void;
  updateRole: IRole | undefined;
  onAdd: (newRole: IRole) => void;
  onUpdate: (updatedRole: IRole) => void;
}

function NewRoleDialog(props: INewRoleDialogProps) {
  const { open, onClose, updateRole, onAdd, onUpdate } = props;
  const isDialogUpdating = updateRole !== undefined;
  const [role, setRole] = useState<IRole>(updateRole ?? { name: '', description: '' })
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [descriptionErrorMessage, setDescriptionErrorMessage] = useState('');
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setRole(updateRole ?? { name: '', description: '' });
    setNameErrorMessage('');
    setDescriptionErrorMessage('');
  }, [open])

  function handleUpdate(updates: Partial<IRole>) {
    const updatedRole: IRole = {
      ...role,
      ...updates
    }

    setRole(updatedRole);
  }

  function isRoleValid(): boolean {
    let valid = true;
    if (role.name === '') {
      setNameErrorMessage('Name is required');
      valid = false;
    }
    if (role.description === '') {
      setDescriptionErrorMessage('Description is required');
      valid = false;
    }

    return valid;
  }

  const handleOkClick = async () => {
    if (!isRoleValid()) {
      return;
    }

    try {
      if (isDialogUpdating) {
        axios.put(`admin/Role?roleName=${role.name}&description=${role.description}`);
        onUpdate(role);
        setAlertMessage('The role was updated successfully.');
      } else {
        axios.post('admin/Role', {...role});
        onAdd(role);
        setAlertMessage('The role was created successfully.');
      }

      onClose();
    } catch (error) {
      setAlertMessage('There was an error trying to save the role.');
      console.log('There was an error trying to save the role.', error);
    }
  }

  return (
    <EnuitDialog
      open={open}
      onClose={onClose}
      title={isDialogUpdating ? 'Update Role' : 'New Role'}
      onOkClick={handleOkClick}
    >
      <Stack direction='column' spacing={2}>
        <div>
          <Typography variant='body2'>Name</Typography>
          <TextField
            size='small'
            fullWidth
            value={role.name}
            onChange={(e) => handleUpdate({ name: e.target.value })}
            disabled={isDialogUpdating}
            error={nameErrorMessage !== ''}
            helperText={nameErrorMessage}
          />
        </div>
        <div>
          <Typography variant='body2'>Description</Typography>
          <TextField
            size='small'
            fullWidth
            value={role.description}
            onChange={(e) => handleUpdate({ description: e.target.value })}
            error={descriptionErrorMessage !== ''}
            helperText={descriptionErrorMessage}
          />
        </div>
      </Stack>
    </EnuitDialog>
  )
}

export default NewRoleDialog