import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import IUser from '../../../interfaces/users/IUser';
import useAxios from '../../../hooks/useAxios';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

type ResetUserPasswordDialogProps = {
  open: boolean;
  onClose: () => void;
  user: IUser;
}

function ResetUserPasswordDialog(props: ResetUserPasswordDialogProps) {
  const { open, onClose, user } = props;
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setNewPassword('');
    setErrorMessage('');
  }, [open])

  const handleUserPasswordReset = async () => {
    if (newPassword === '') {
      setErrorMessage('New password is required')
      return;
    }

    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$");
    if (!passwordRegex.test(newPassword)) {
      setErrorMessage('Password must contain at least one uppercase letter, one number, and one special character');
      return;
    }

    try {
      await axios.post('admin/User/ResetPassword', { user: {...user}, password: newPassword });
      onClose();
      setAlertMessage('Password reset successfully.');
    } catch (error) {
      setAlertMessage('An error occurred while resetting the password.');
      console.log('An error occurred while resetting the password.', error);
    }
  }
   
  return (
    <EnuitDialog
      title='Reset User Password'
      onOkClick={handleUserPasswordReset}
      open={open}
      onClose={onClose}
    >
      <Typography>New User Password</Typography>
      <TextField
        id='not-a-password'
        size='small'
        fullWidth
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
        error={errorMessage !== ''}
        helperText={errorMessage}
        type={passwordVisible ? 'text' : 'password'}
        InputProps={{
          endAdornment: 
            <InputAdornment position='end'>
              <IconButton onClick={() => setPasswordVisible(prev => !prev)} edge='end'>
                {passwordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
        }}
      />
    </EnuitDialog>
  )
}

export default ResetUserPasswordDialog