import React from 'react'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IClient from '../../../interfaces/clients/IClient';

interface IClientDetailProps {
  client: IClient;
  handleUpdate: (updates: Partial<IClient>) => void;
  idErrorMessage?: string;
  areDetailsUpdating: boolean;
}

function ClientDetails(props: IClientDetailProps) {
  const { client, handleUpdate, idErrorMessage, areDetailsUpdating } = props;
  let errorMessage = idErrorMessage??'';

  const handleTokenLifetimeUpdate = (value: string) => {
    const formattedValue = parseInt(value);
    if (formattedValue > 0) {
      handleUpdate({absoluteRefreshTokenLifetime: formattedValue});
    } else if (Number.isNaN(formattedValue)) {
      handleUpdate({absoluteRefreshTokenLifetime: 1});
    }
  }

  return (
    <div id='new-client-dialog'>
      <div>
        <Typography variant='body2'>Id</Typography>
        <TextField
          id='client-id'
          size='small'
          fullWidth
          value={client.clientId}
          onChange={(e) => {errorMessage = ''; handleUpdate({clientId: e.target.value});}}
          error={errorMessage !== ''}
          helperText={errorMessage}
          disabled={areDetailsUpdating}
        />
      </div>
      <div>
        <Typography variant='body2'>Name</Typography>
        <TextField 
          id='client name'
          size='small'
          fullWidth
          value={client.clientName??''}
          onChange={(e) => handleUpdate({clientName: e.target.value === '' ? null : e.target.value})}
        />
      </div>
      <div>
        <Typography variant='body2'>Description</Typography>
        <TextField 
          id='client-description'
          size='small'
          fullWidth
          value={client.description??''}
          onChange={(e) => handleUpdate({description: e.target.value === '' ? null : e.target.value})}
        />
      </div>
      <div>
        <Typography variant='body2'>Client URI</Typography>
        <TextField
          id='client-uri'
          size='small'
          fullWidth
          value={client.clientUri??''}
          onChange={(e) => handleUpdate({clientUri: e.target.value === '' ? null : e.target.value})}
        />
      </div>
      <div>
        <Typography variant='body2'>Logout URI</Typography>
        <TextField 
          id='client-logout'
          size='small'
          fullWidth
          value={client.logoUri??''}
          onChange={(e) => handleUpdate({logoUri: e.target.value === '' ? null : e.target.value})}
        />
      </div>
      <div>
        <Typography variant='body2'>Refresh Token Lifetime (Hours)</Typography>
        <TextField 
          id='client-token-lifetime'
          size='small'
          fullWidth
          value={client.absoluteRefreshTokenLifetime}
          onChange={(e) => handleTokenLifetimeUpdate(e.target.value)}
        />
      </div>
      <div className='checkboxes'>
        <FormControlLabel 
          control={
            <Checkbox 
              checked={client.requireClientSecret}
              value={client.requireClientSecret??''}
              onChange={(e) => handleUpdate({requireClientSecret: e.target.checked})}
            />
          } 
          label='Require Secret' 
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={client.requireConsent}
              value={client.requireConsent??''}
              onChange={(e) => handleUpdate({requireConsent: e.target.checked})}
            />
          } 
          label='Require Consent' 
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={client.requirePkce}
              value={client.requirePkce}
              onChange={(e) => handleUpdate({requirePkce: e.target.checked})}
            />
          } 
          label='Require PKCE' 
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={client.allowOfflineAccess}
              value={client.allowOfflineAccess}
              onChange={(e) => handleUpdate({allowOfflineAccess: e.target.checked})}
            />
          } 
          label='Allow Offline Access' 
        />
         <FormControlLabel 
          control={
            <Checkbox 
              checked={client.enabled}
              value={client.enabled}
              onChange={(e) => handleUpdate({enabled: e.target.checked})}
            />
          } 
          label='Enabled' 
        />
      </div>      
    </div>
  )
}

export default ClientDetails