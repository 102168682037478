import React, { useState, useEffect, useContext } from 'react';
import EnuitDialog from '../../../components/dialog/EnuitDialog';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Autocomplete, Box, FormControl, FormHelperText, Snackbar } from '@mui/material';
import useAxios from '../../../hooks/useAxios';
import { EnuitAlertContext } from '../../../components/alert/EnuitAlert';

type GenerateTokenDialogProps = {
  open: boolean;
  onClose: () => void;
  scopes: string[];
  clientId: string;
}

type TokenResponse = {
  token: string;
}

function GenerateClientTokenDialog(props: GenerateTokenDialogProps) {
  const { open, onClose, scopes, clientId } = props
  const [scopeErrorMessage, setScopeErrorMessage] = useState('');
  const [secretErrorMessage, setSecretErrorMessage] = useState('');
  const [scope, setScope] = useState(scopes[0] ?? '');
  const [secret, setSecret] = useState('');
  const [token, setToken] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const axios = useAxios();
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  useEffect(() => {
    setScope(scopes[0]);
    setScopeErrorMessage('');
    setSecret('');
    setSecretErrorMessage('');
    setToken('');
  }, [open])

  useEffect(() => { setScope(scopes[0]) }, [scopes])

  const handleGenerateToken = async () => {
    if (scope === '') {
      setScopeErrorMessage('Scope is required');
      return;
    } else {
      setScopeErrorMessage('');
    }

    if (secret === '') {
      setSecretErrorMessage('Secret is required');
      return;
    } else {
      setSecretErrorMessage('');
    }

    try {
      const response = await axios.post<TokenResponse>('admin/Client/Token', { clientId: clientId, secret: secret, scope: scope });
      setToken(response.data.token);
      setAlertMessage('Client token generated successfully.');
    } catch (error) {
      setAlertMessage('An error ocurred while generation.');
      console.log('An error ocurred while generation')
    }
  }

  const handleCopyToken = () => {
    if (token !== '') {
      navigator.clipboard.writeText(token);
      setCopySuccess(true);
    }
  }


  return (
    <EnuitDialog
      title='Generate Client Token'
      open={open}
      onClose={onClose}
      hideOkButton
    >
      <>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={copySuccess} onClose={() => setCopySuccess(false)} autoHideDuration={3000}>
          <Alert variant='filled' onClose={() => setCopySuccess(false)} severity="success">
            Copied Successfully
          </Alert>
        </Snackbar>
        <Stack direction='column' spacing={2}>
          <Box>
            <Typography>Scope</Typography>
            <FormControl
              fullWidth
              error={scopeErrorMessage !== ''}
            >
              <Autocomplete
                autoHighlight
                options={scopes}
                renderInput={(params) => <TextField {...params} />}
                size='small'
                value={scope}
                onChange={(e, value) => setScope(value)}
                disableClearable
              />
              <FormHelperText>{scopeErrorMessage}</FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <Typography>Secret</Typography>
            <TextField
              id='generate-token-secret'
              fullWidth
              size='small'
              helperText={secretErrorMessage}
              error={secretErrorMessage !== ''}
              value={secret}
              onChange={e => setSecret(e.target.value)}
              type='password'
            />
          </Box>
          <span><Button variant='outlined' onClick={handleGenerateToken}>Generate Token</Button></span>
          <Paper variant='outlined' sx={{ p: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography noWrap sx={{ display: 'block' }}>{token === '' ? 'Generated token will display here' : token}</Typography>
            <IconButton onClick={handleCopyToken}>
              <ContentCopyIcon fontSize='small' sx={{ alignSelf: 'flex-end' }} />
            </IconButton>
          </Paper>
        </Stack>
      </>
    </EnuitDialog>
  )
}

export default GenerateClientTokenDialog