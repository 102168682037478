import React, { useState, useEffect, useRef, useContext } from 'react';
// MUI
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import PageHeader from '../../components/PageHeader/PageHeader';
import Box from '@mui/material/Box';
//Other
import useAxios from '../../hooks/useAxios';
import IClaim from '../../interfaces/claims/IClaim';
import List, { listItem } from '../../components/List/List';
import { Divider, Typography } from '@mui/material';
import ConfirmDeleteDialog from '../../components/dialog/ConfirmDeleteDialog';
import NewClaimTypeDialog from './NewClaimTypeDialog';
import ax from 'axios';
import { useNavigate } from 'react-router-dom';
import { EnuitAlertContext } from '../../components/alert/EnuitAlert';

interface IDeleteClaimResponse {
  success: boolean;
  message: string;
}

function ClaimTypes() {
  const [search, setSearch] = useState('');
  const [claimTypes, setClaimTypes] = useState<IClaim[]>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newClaimOpen, setNewClaimOpen] = useState(false);

  const claimTypeToDelete = useRef<number | undefined>(undefined);
  const [alertMessage, setAlertMessage] = useContext(EnuitAlertContext);

  const axios = useAxios();
  const navigate = useNavigate();
  
  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    try {
      const response = await axios.get<IClaim[]>('admin/ClaimType');
      setClaimTypes(response.data);
    } catch (error) {
      if (ax.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate('/unauthorized');
          return;
        }
      }
      console.log('An error occurred while loading the claim types.', error);
    }
  }

  const onAddClaimTypeButtonClick = () => {
    setNewClaimOpen(true);
  }

  const sortByClaim = (a: IClaim, b: IClaim) => {
    if (a.type.toLocaleLowerCase() < b.type.toLocaleLowerCase()) {
      return -1;
    } else if (b.type.toLocaleLowerCase() < a.type.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  }

  const startDeleteClaimType = (id: string) => {
    claimTypeToDelete.current = parseInt(id);
    setDeleteOpen(true);
  }

  const handleDeleteClaimType = async () => {
    if (claimTypeToDelete.current !== undefined) {
      try {
        const response = await axios.delete<IDeleteClaimResponse>('admin/ClaimType?id=' + claimTypeToDelete.current);
        if (response.data.success) {
          const newState = claimTypes.filter(claimType => claimType.e7ClaimTypeId !== claimTypeToDelete.current);
          setClaimTypes(newState);
          setDeleteOpen(false);
          setAlertMessage('The claim type was deleted successfully.');
        }
      } catch (error) {
        setAlertMessage('An error occurred while deleting a claim type.');
        console.log('An error occurred while deleting a claim type.', error);
      }
    }
  }

  return (
    <>
      <NewClaimTypeDialog
        open={newClaimOpen}
        onClose={() => setNewClaimOpen(false)}
        onAdd={e => setClaimTypes(prev => ([...prev]).concat(e))}
        setAlertMessage={setAlertMessage}
      />
      <ConfirmDeleteDialog
        open={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={handleDeleteClaimType}
      />
      <Stack id='claim-types' direction='column' spacing={4}>
        <PageHeader
          pageName='Claim Types'
          searchValue={search}
          onSearchFieldChange={setSearch}
          button={{ buttonText: "Add Claim Type", handleButtonClick: onAddClaimTypeButtonClick, startIcon: <AddIcon /> }}
        />
        <Box>
          <Box pt={2} pb={2}>
            <Typography variant='subtitle2'>Custom Claim Types</Typography>
            <Divider />
          </Box>
          <List
            listData={
              claimTypes
                .sort((a, b) => sortByClaim(a, b))
                .filter(claimType => (
                  !claimType.isJwtClaimType
                  && (search !== '' ?
                    claimType.type.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || claimType.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    : true
                  )
                ))
                .map((claimType): listItem => ({
                  title: claimType.type,
                  bodyText: claimType.description ?? '',
                  action: {
                    type: 'error',
                    itemId: claimType.e7ClaimTypeId.toString(),
                    actionText: 'Delete',
                    actionHandler: startDeleteClaimType,
                    variant: 'outlined'
                  }
                }))
            }
          />
        </Box>
        <Box>
          <Box pt={2} pb={2}>
            <Typography variant='subtitle2'>JWT Claim Types</Typography>
            <Divider />
          </Box>
          <List
            listData={
              claimTypes
                .sort((a, b) => sortByClaim(a, b))
                .filter(claimType => (
                  claimType.isJwtClaimType
                  && (search !== '' ?
                    claimType.type.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    || claimType.description?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    : true
                  )
                ))
                .map((claimType): listItem => ({
                  title: claimType.type,
                  bodyText: claimType.description ?? ''
                }))
            }
          />
        </Box>
      </Stack>
    </>
  )
}

export default ClaimTypes